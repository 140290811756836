.questionary-section {
    position: relative;
    background-color: #F7ECE4;
    width: 100%;
    height: auto;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('./images/backgroundOurWeddingUpSideDown.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.2;
    }

    >* {
        position: relative;
    }

    &__container {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .title {
            position: relative;
            display: flex;
            justify-content: space-evenly;
            text-align: center;

            &__line {
                width: 377px;
                height: 67px;
            }

            &__text {
                position: absolute;
                font-family: "Kyiv Type Titling Medium3", sans-serif;
                font-size: 24px;
                font-weight: 400;
                line-height: 1;
                color: var(--color-brand-dark);
                padding: 15px;
            }

        }

        .container-content {
            margin: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 30px;


            .questionary-section-text {
                font-family: "Kyiv Type Titling", sans-serif;
                font-size: 16px;
                font-weight: 400;
                color: var(--color-brand-dark);
                width: 456px;

                @media screen and (max-width: $bp-mobile) {
                    width: 256px;
                }
            }

            .questionary-section-text-colours {
                font-family: "Kyiv Type Titling", sans-serif;
                font-size: 24px;
                font-weight: 400;
                color: var(--color-brand-dark);
            }

            .colours {
                display: flex;
                flex-direction: column;
                align-content: center;
                gap: 20px;

                &__dark {
                    display: flex;
                    gap: 30px;

                    .brown {
                        width: 93px;
                        height: 93px;
                        border-radius: 50%;
                        border: 1px solid var(--color-primary-dark);
                        background-color: #412A1B;
                    }

                    .green {
                        width: 93px;
                        height: 93px;
                        border-radius: 50%;
                        border: 1px solid var(--color-primary-dark);
                        background-color: #334F23;
                    }

                    .light-green {
                        width: 93px;
                        height: 93px;
                        border-radius: 50%;
                        border: 1px solid var(--color-primary-dark);
                        background-color: #93AD82;
                    }
                }

                &__light {
                    display: flex;
                    gap: 30px;

                    .beige {
                        width: 93px;
                        height: 93px;
                        border-radius: 50%;
                        border: 1px solid var(--color-primary-dark);
                        background-color: #CFBDA9;
                    }

                    .pink {
                        width: 93px;
                        height: 93px;
                        border-radius: 50%;
                        border: 1px solid var(--color-primary-dark);
                        background-color: #F7DADA;
                    }

                    .milk {
                        width: 93px;
                        height: 93px;
                        border-radius: 50%;
                        border: 1px solid var(--color-primary-dark);
                        background-color: #F8F0E8;
                    }
                }
            }
        }
    }

    .dividingLines {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .first-dividingLine {
            position: absolute;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-size: 24px;
            width: 100%;
            color: var(--color-primary-dark);
            line-height: 0;
        }

        .second-dividingLine {
            margin: 15px 0;
            border: 2px solid var(--color-primary-dark);
        }

        .third-dividingLine {
            position: absolute;
            padding: 15px 0;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 36px;
            width: 100%;
            color: var(--color-primary-dark);
            line-height: 0;
        }
    }
}