.locations-section {
    position: relative;
    background-color: #F7ECE4;
    width: 100%;
    height: auto;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('./images/backgroundOurWedding.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.2;
    }

    >* {
        position: relative;
    }

    &__container {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .title {
            position: relative;
            display: flex;
            justify-content: space-evenly;
            text-align: center;

            &__line {
                width: 377px;
                height: 67px;
            }

            &__text {
                position: absolute;
                font-family: "Kyiv Type Titling Medium3", sans-serif;
                font-size: 24px;
                font-weight: 400;
                line-height: 1;
                color: var(--color-brand-dark);
                padding: 15px;
            }

        }

        .container-content {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 10px;

            .content-text {
                font-family: "Kyiv Type Titling Medium3", sans-serif;
                font-size: 16px;
                color: var(--color-brand-dark);
                margin: 10px 40px;
            }

            .church-map,
            .restaurant-map {
                position: relative;
                border: none;
                border-radius: 10px;
                width: 352px;
                height: 324px;
                pointer-events: auto;

                @media screen and (max-width: $bp-mobile) {
                    width: 252px;
                    height: 224px;
                    border-radius: 16px;
                }
            }

            .map {
                width: 292px;
                height: 492px;
                border-radius: 16px;

                @media screen and (max-width: $bp-mobile) {
                    width: 202px;
                    height: 352px;

                }
            }
        }
    }

    .dividingLines {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .first-dividingLine {
            position: absolute;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-size: 24px;
            width: 100%;
            color: var(--color-primary-dark);
            line-height: 0;
        }

        .second-dividingLine {
            margin: 15px 0;
            border: 2px solid var(--color-primary-dark);
        }

        .third-dividingLine {
            position: absolute;
            padding: 15px 0;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 36px;
            width: 100%;
            color: var(--color-primary-dark);
            line-height: 0;
        }
    }
}