.timing-section {
    position: relative;
    background-color: #F7ECE4;
    width: 100%;
    height: auto;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('./images/backgroundOurWedding.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.2;
    }

    >* {
        position: relative;
    }

    &__container {
        display: flex;
        flex-direction: column;

        .title {
            position: relative;
            display: flex;
            justify-content: space-evenly;
            text-align: center;
            margin-top: 20px;

            &__line {
                width: 377px;
                height: 67px;
            }

            &__text {
                position: absolute;
                font-family: "Kyiv Type Titling Medium3", sans-serif;
                font-size: 24px;
                font-weight: 400;
                line-height: 1;
                color: var(--color-brand-dark);
                padding: 15px;
            }

        }

        .container-content {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 5px 10px;
            gap: 10px;

            @media screen and (min-width:$bp-mobile) {
                justify-content: space-evenly;
            }

            .content-icons {
                display: flex;
                flex-direction: column;
                gap: 55px;
                margin-top: 40px;

                .busBack {
                    transform: scaleX(-1);
                }
            }

            .content-points {
                position: relative;
                display: flex;
                flex-direction: column;

                &::before {
                    content: "";
                    position: absolute;
                    top: 40;
                    bottom: 0;
                    left: 10px;
                    right: 10px;
                    background-color: var(--color-primary-dark);
                    width: 1.5px;
                    height: 95%;

                    @media screen and (max-width:$bp-mobile) {
                        width: 1px;
                    }
                }

                .wrapper-point {
                    margin-top: 68px;
                    height: 71px;

                    &:nth-child(n+4) {
                        height: 72px;

                        @media screen and (max-width:$bp-mobile) {
                            height: 42px;
                        }
                    }

                    @media screen and (max-width:$bp-mobile) {
                        height: 41px;
                    }

                    .point {

                        display: flex;
                        justify-content: center;
                        width: 21px;
                        height: 21px;
                        border: 1px solid var(--color-primary-dark);
                        border-radius: 50%;
                        align-self: center;
                        z-index: 1;

                        .point__inner {
                            width: 16px;
                            height: 16px;
                            background-color: var(--color-brand-light);
                            border-radius: 50%;
                            align-self: center;
                            box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.3);
                            z-index: 1;
                        }
                    }
                }
            }

            .content-allText {
                display: flex;

                .content-text {
                    display: flex;
                    flex-direction: column;
                    margin-top: 65px;
                    max-width: 272px;
                    font-family: "Kyiv Type Titling Medium3", sans-serif;
                    font-size: 14px;
                    color: var(--color-brand-dark);
                    gap: 55px;

                    .time {
                        height: 85px;
                        white-space: nowrap;

                        @media screen and (max-width:$bp-mobile) {
                            height: 55px;
                        }
                    }

                    .content {
                        height: 85px;

                        @media screen and (max-width:$bp-mobile) {
                            height: 55px;
                        }
                    }
                }
            }
        }
    }

    .dividingLines {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .first-dividingLine {
            position: absolute;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 24px;
            width: 100%;
            color: var(--color-primary-dark);
            line-height: 0;
        }

        .second-dividingLine {
            margin: 15px 0;
            border: 2px solid var(--color-primary-dark);
        }

        .third-dividingLine {
            position: absolute;
            padding: 15px 0;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-size: 36px;
            width: 100%;
            color: var(--color-primary-dark);
            line-height: 0;
        }
    }
}