.mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, #ADA195, #F7ECE4);
    padding: 16px 24px;
    overflow-y: auto;
    text-align: left;
    transform: translateX(100%);
    transition: transform 0.3s;

    &.is-open {
        transform: translateX(0);
        z-index: 15;
    }

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        margin-bottom: 32px;
        text-align: center;

        @media screen and (max-width:$bp-mobile) {
            margin-bottom: 14px;
        }

        &.logo {
            width: 220px;
            height: 220px;
            align-self: center;

            @media screen and (max-width:$bp-mobile) {
                width: 120px;
                height: 120px;
            }
        }
    }

    &__subitem {
        line-height: 3;

        @media screen and (max-width:$bp-mobile) {
            line-height: 0.8;
        }
    }
}