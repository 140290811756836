.hero-section {
    position: relative;
    padding: 115px 0 0 0;
    background-image: url('./images/backgroundHero.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    .rectangleCover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(247, 236, 228, 0.6) 0%,
                rgba(205, 193, 183, 0.5) 35%,
                rgba(173, 161, 149, 0.4) 100%);
        z-index: 10;
        max-width: 768px;
    }

    .container {
        display: flex;
        flex-direction: column;

        &__title {
            padding: 25px 32px 5px 32px;

            .names {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                font-family: "Kyiv Type Titling Heavy3", sans-serif;
                font-size: 64px;
                font-weight: 900;
                line-height: 1;

                @media screen and (max-width:$bp-mobile) {
                    font-size: 48px;
                }

                .Severyn {
                    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                    align-self: flex-start;
                    z-index: 11;
                }

                .pu33le {
                    z-index: 11;

                    .pu33leImg {
                        width: 166px;
                        height: 131px;
                        padding-bottom: 5px;

                        @media screen and (max-width:$bp-mobile) {
                            width: 120px;
                            height: 96px;
                        }
                    }

                    @media screen and (max-width:$bp-mobile) {
                        transform: translate(50%, 10%);
                    }
                }

                .Khrystyna {
                    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                    align-self: flex-end;
                    z-index: 11;
                }
            }

            @media screen and (min-width:$bp-tablet) {
                margin-left: 15%;
            }
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: column;

            .rectangle1 {
                position: relative;
                width: 302px;
                height: 402px;
                border: solid 2px;
                border-color: var(--color-brand-dark);
                border-radius: 94px;
                left: 50%;
                transform: translateX(-50%);

                .rectangle2 {
                    position: absolute;
                    width: 302px;
                    height: 402px;
                    border: solid 2px;
                    border-color: var(--color-brand-light);
                    border-radius: 94px;
                    margin-left: 7px;
                    margin-top: 7px;
                    left: 47%;
                    transform: translateX(-47%);
                    z-index: 11;


                    .branchImg {
                        left: 0;
                        width: 220px;
                        height: 420px;
                        left: 17%;
                        transform: translateX(-17%);
                        z-index: 11;
                    }
                }

                .rectangle3 {
                    position: absolute;
                    width: 302px;
                    height: 402px;
                    border: solid 2px;
                    border-color: var(--color-primary-dark);
                    border-radius: 94px;
                    margin-left: 14px;
                    margin-top: 14px;
                    left: 44%;
                    transform: translateX(-44%);
                }
            }

            .content-button {
                position: relative;
                align-self: flex-end;
                right: 0;
                top: -70px;
                z-index: 11;
            }
        }
    }

    &__photo {
        position: absolute;
        margin-left: 15px;
        width: 267px;
        height: 355px;
    }
}