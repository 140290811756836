*,
*::after,
*::before {
    box-sizing: border-box;
}

body {
    color: var(--color-primary-dark);
    background-color: var(--color-brand-light);
    font-family: "Kyiv Type Titling", sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    max-width: 768px;
    margin: auto;

    @media screen and (min-width:$bp-tablet) {
        font-size: 18px;
    }
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
p {
    margin-top: 0;
}

a {
    display: inline-block;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

.icon {
    width: 85;
    height: 85;

    @media screen and (max-width:$bp-mobile) {
        width: 55;
        height: 55;
    }
}

@font-face {
    font-family: 'Kyiv Type Titling Bold';
    src: url('./fonts/KyivTypeTitling-Bold.woff2') format('woff2'),
        url('./fonts/KyivTypeTitling-Bold.woff') format('woff');
    font-weight: 900px;
}

@font-face {
    font-family: 'Kyiv Type Titling';
    src: url('./fonts/KyivTypeTitling-Regular3.woff2') format('woff2'),
        url('./fonts/KyivTypeTitling-Regular3.woff') format('woff');
    font-weight: 400px;
}

@font-face {
    font-family: 'Kyiv Type Titling Medium3';
    src: url('./fonts/KyivTypeTitling-Medium3.woff2') format('woff2'),
        url('./fonts/KyivTypeTitling-Medium3.woff') format('woff');
    font-weight: 600px;
}

@font-face {
    font-family: 'Kyiv Type Titling Heavy3';
    src: url('./fonts/KyivTypeTitling-Heavy3.woff2') format('woff2'),
        url('./fonts/KyivTypeTitling-Heavy3.woff') format('woff');
    font-weight: 900px;
}