.fill-questionnaire-button,
.photo-content-button {
    color: var(--color-brand-dark);
    border-radius: 100%;
    border: 1px solid var(--color-brand-light);
    background: var(--color-primary-dark);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    font-family: "Kyiv Type Titling", sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 12px 12px;
    width: 120px;
    height: 120px;
    margin-right: 32px;

    &:hover {
        color: var(--color-primary-dark);
        background: var(--color-brand-light);
    }

    &.questionary-section__button {
        margin: 0;
    }
}

.photo-content-button {
    margin: 0;
}

.close-menu {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 0px;
    padding: 0;
    color: var(--color-brand-dark);

    &:focus {
        color: var(--color-primary-dark);
    }

    &__cross-arrow {
        display: inline-block;
        width: 24px;
        height: 24px;
        stroke-width: 0;
        stroke: currentColor;
        fill: transparent;
    }
}