.text-brand {
    color: var(--color-brand-light);
}

.text-brand-dark {
    color: var(--color-brand-dark);
}

.text-primary-dark {
    color: var(--color-primary-dark);
}

.text-bold {
    font-weight: 600;
}

.uppercase {
    text-transform: uppercase;
}