.header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 0;
    width: 100%;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-logo {
            margin-left: 15px;
            width: 120px;
            position: relative;
            height: 120px;
            z-index: 14;
        }

        .header-rectangle {
            position: absolute;
            align-self: center;
            width: 100%;
            height: 80px;
            background: linear-gradient(to left, #ADA195, #F7ECE4);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
            z-index: 13;
        }

        .burger {
            margin-right: 15px;
            height: 24px;
            background-color: transparent;
            border: 0px;
            fill: var(--color-brand-dark);
            z-index: 14;

            &:focus {
                fill: var(--color-primary-dark);
            }

            &__icon {
                width: 24px;
                height: 24px;
            }
        }
    }
}