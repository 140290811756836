.transfer-section {
    position: relative;
    background-color: #F7ECE4;
    width: 100%;
    height: auto;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('./images/backgroundOurWeddingUpSideDown.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.2;
    }

    >* {
        position: relative;
    }

    &__container {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .title {
            position: relative;
            display: flex;
            justify-content: space-evenly;
            text-align: center;

            &__line {
                width: 377px;
                height: 67px;
            }

            &__text {
                position: absolute;
                font-family: "Kyiv Type Titling Medium3", sans-serif;
                font-size: 24px;
                font-weight: 400;
                line-height: 1;
                color: var(--color-brand-dark);
                padding: 15px;
            }

        }

        .container-content {
            margin: 20px;
            display: flex;
            flex-direction: column;

            .start-point {
                display: flex;
                margin: 20px;
                gap: 20px;

                &__icon {
                    width: 47px;
                    height: 42px;
                }

                &__text {
                    font-family: "Kyiv Type Titling Medium3", sans-serif;
                    font-size: 24px;
                    font-weight: 400;
                    color: var(--color-brand-dark);

                    @media screen and (max-width: $bp-mobile) {
                        font-size: 20px;
                    }
                }
            }

            .stop-points {
                position: relative;
                display: flex;
                padding-top: 10px;
                margin-bottom: 20px;

                &__icons {
                    display: flex;
                    flex-direction: column;

                    .stop-point__icon {
                        margin: 10px 5px;
                        width: 48px;
                        height: 69px;

                        @media screen and (max-width:$bp-mobile) {
                            width: 38px;
                            height: 59px;
                        }
                    }
                }

                &__text {
                    display: flex;
                    flex-direction: column;
                    font-family: "Kyiv Type Titling Medium3", sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: var(--color-brand-dark);

                    @media screen and (max-width: $bp-mobile) {
                        font-size: 14px;
                    }

                    .stop-point__text {
                        margin: 5px 15px;
                        height: 79px;
                        line-height: 69px;

                        @media screen and (max-width:$bp-mobile) {
                            height: 69px;
                            line-height: 59px;

                            &.zero {
                                padding-top: 20px;
                                line-height: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .dividingLines {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .first-dividingLine {
            position: absolute;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 24px;
            width: 100%;
            color: var(--color-primary-dark);
            line-height: 0;
        }

        .second-dividingLine {
            margin: 15px 0;
            border: 2px solid var(--color-primary-dark);
        }

        .third-dividingLine {
            position: absolute;
            padding: 15px 0;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-size: 36px;
            width: 100%;
            color: var(--color-primary-dark);
            line-height: 0;
        }
    }
}