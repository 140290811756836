.nav-link {
    text-decoration: none;
    font-size: 48px;
    font-weight: 400;
    color: var(--color-brand-dark);

    @media screen and (max-width:$bp-mobile) {
        font-size: 24px;
    }

    &:hover {
        color: var(--color-primary-dark);
        font-weight: 600;
    }

    &__subitem {
        font-size: 24px;
        font-weight: 400;
        color: var(--color-brand-dark);
        text-decoration: none;

        @media screen and (max-width:$bp-mobile) {
            font-size: 16px;
        }

        &:hover {
            color: var(--color-primary-dark);
            font-weight: 600;
        }
    }
}