.donations-section {
    position: relative;
    background-color: #F7ECE4;
    width: 100%;
    height: auto;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('./images/backgroundOurWeddingUpSideDown.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.2;
    }

    >* {
        position: relative;
    }

    &__container {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .title {
            position: relative;
            display: flex;
            justify-content: space-evenly;
            text-align: center;

            &__line {
                width: 377px;
                height: 67px;
            }

            &__text {
                position: absolute;
                font-family: "Kyiv Type Titling Medium3", sans-serif;
                font-size: 24px;
                font-weight: 400;
                line-height: 1;
                color: var(--color-brand-dark);
                padding: 15px;
            }

        }

        .container-content {
            margin: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 40px;

            .text-wrapper {
                width: 456px;

                @media screen and (max-width: $bp-mobile) {
                    width: 256px;
                    line-height: 0.5;
                }
            }

            .donations-section-text {
                font-family: "Kyiv Type Titling", sans-serif;
                font-size: 20px;
                font-weight: 400;
                color: var(--color-brand-dark);
                width: 456px;

                @media screen and (max-width: $bp-mobile) {
                    width: 256px;
                }

                &.highlited {
                    color: black;
                    font-weight: 600;
                }
            }

            .donationQR {
                width: 181px;
                height: 181px;
                border-radius: 10px;
            }
        }
    }
}