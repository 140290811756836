.ourWedding-section {
    position: relative;
    background-color: #F7ECE4;
    width: 100%;
    height: auto;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('./images/backgroundOurWeddingUpSideDown.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.2;
    }

    >* {
        position: relative;
    }

    &__container {
        display: flex;
        flex-direction: column;

        .title-rectangle {
            text-align: right;
            align-self: center;
            width: 100%;
            height: 80px;
            background: linear-gradient(to left, rgba(247, 236, 228, 1) 0%,
                    rgba(99, 106, 96, 0.8) 100%);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);


            .title-text {
                font-family: "Kyiv Type Titling", sans-serif;
                font-size: 36px;
                font-weight: 400;
                line-height: 1;
                color: var(--color-brand-dark);
                padding: 10px;
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 5px;

        @media screen and (max-width:$bp-mobile) {
            right: 2%;
        }

        .rectangle1 {
            position: relative;
            width: 323px;
            height: 210px;
            border: solid 2px;
            border-color: var(--color-primary-dark);
            border-radius: 94px;

            .rectangle2 {
                position: absolute;
                width: 323px;
                height: 210px;
                border: solid 2px;
                border-color: var(--color-brand-light);
                border-radius: 94px;
                margin-left: 4px;
                margin-top: 4px;

                .branch {
                    position: relative;
                    display: flex;

                    .branchImg {
                        position: absolute;
                        width: 145px;
                        height: 215px;
                        left: 72%;
                        transform: translateX(-17%);
                    }
                }
            }

            .rectangle3 {
                position: absolute;
                width: 323px;
                height: 210px;
                border: solid 2px;
                border-color: var(--color-brand-dark);
                border-radius: 94px;
                margin-left: 8px;
                margin-top: 8px;

                .content-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-family: 'Kyiv Type Titling Medium3';
                    font-size: 20px;

                    .month {
                        margin-top: 15px;
                    }

                    .concrete-date {
                        display: flex;

                        .weekDay {
                            position: relative;
                            display: inline-block;
                            align-self: center;
                            margin-right: 15px;

                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                border-bottom: 1px solid var(--color-brand-dark);
                                opacity: 0.7;
                                pointer-events: none;
                            }

                            &::after {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                border-bottom: 1px solid var(--color-brand-dark);
                                opacity: 0.7;
                                pointer-events: none;
                            }
                        }

                        .date {
                            font-size: 48px;
                            color: var(--color-brand-dark);
                        }

                        .time {
                            position: relative;
                            display: inline-block;
                            align-self: center;
                            margin-left: 15px;

                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                border-bottom: 1px solid var(--color-brand-dark);
                                opacity: 0.7;
                                pointer-events: none;
                            }

                            &::after {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                border-bottom: 1px solid var(--color-brand-dark);
                                opacity: 0.7;
                                pointer-events: none;
                            }
                        }
                    }

                    .place {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .dividingLine {
        margin: 0;
        border: 2px solid var(--color-primary-dark);
    }
}