:root {
  --color-brand-light: #f7ece4;
  --color-brand-dark: #636a60;
  --color-primary-dark: #ada195;
}

.text-brand {
  color: var(--color-brand-light);
}

.text-brand-dark {
  color: var(--color-brand-dark);
}

.text-primary-dark {
  color: var(--color-primary-dark);
}

.text-bold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

*, :after, :before {
  box-sizing: border-box;
}

body {
  color: var(--color-primary-dark);
  background-color: var(--color-brand-light);
  max-width: 768px;
  margin: auto;
  font-family: Kyiv Type Titling, sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 1.5;
}

@media screen and (width >= 768px) {
  body {
    font-size: 18px;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1, h2, h3, p {
  margin-top: 0;
}

a {
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.icon {
  width: 85px;
  height: 85px;
}

@media screen and (width <= 480px) {
  .icon {
    width: 55px;
    height: 55px;
  }
}

@font-face {
  font-family: Kyiv Type Titling Bold;
  src: url("KyivTypeTitling-Bold.61b32c8f.woff2") format("woff2"), url("KyivTypeTitling-Bold.032eadfe.woff") format("woff");
  font-weight: 900px;
}

@font-face {
  font-family: Kyiv Type Titling;
  src: url("KyivTypeTitling-Regular3.8295c5ac.woff2") format("woff2"), url("KyivTypeTitling-Regular3.333732d7.woff") format("woff");
  font-weight: 400px;
}

@font-face {
  font-family: Kyiv Type Titling Medium3;
  src: url("KyivTypeTitling-Medium3.a882603b.woff2") format("woff2"), url("KyivTypeTitling-Medium3.51ef3d01.woff") format("woff");
  font-weight: 600px;
}

@font-face {
  font-family: Kyiv Type Titling Heavy3;
  src: url("KyivTypeTitling-Heavy3.7ea877f2.woff2") format("woff2"), url("KyivTypeTitling-Heavy3.27e2c3bc.woff") format("woff");
  font-weight: 900px;
}

.mobile-menu {
  text-align: left;
  background: linear-gradient(to left, #ada195, #f7ece4);
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  transition: transform .3s;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: auto;
  transform: translateX(100%);
}

.mobile-menu.is-open {
  z-index: 15;
  transform: translateX(0);
}

.mobile-menu__list {
  flex-direction: column;
  display: flex;
}

.mobile-menu__item {
  text-align: center;
  margin-bottom: 32px;
}

@media screen and (width <= 480px) {
  .mobile-menu__item {
    margin-bottom: 14px;
  }
}

.mobile-menu__item.logo {
  align-self: center;
  width: 220px;
  height: 220px;
}

@media screen and (width <= 480px) {
  .mobile-menu__item.logo {
    width: 120px;
    height: 120px;
  }
}

.mobile-menu__subitem {
  line-height: 3;
}

@media screen and (width <= 480px) {
  .mobile-menu__subitem {
    line-height: .8;
  }
}

.fill-questionnaire-button, .photo-content-button {
  color: var(--color-brand-dark);
  border: 1px solid var(--color-brand-light);
  background: var(--color-primary-dark);
  cursor: pointer;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  margin-right: 32px;
  padding: 12px;
  font-family: Kyiv Type Titling, sans-serif;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 4px 6px #0000004d;
}

.fill-questionnaire-button:hover, .photo-content-button:hover {
  color: var(--color-primary-dark);
  background: var(--color-brand-light);
}

.fill-questionnaire-button.questionary-section__button, .photo-content-button.questionary-section__button, .photo-content-button {
  margin: 0;
}

.close-menu {
  color: var(--color-brand-dark);
  background-color: #0000;
  border: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  top: 16px;
  right: 24px;
}

.close-menu:focus {
  color: var(--color-primary-dark);
}

.close-menu__cross-arrow {
  stroke-width: 0;
  stroke: currentColor;
  fill: #0000;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.nav-link {
  color: var(--color-brand-dark);
  font-size: 48px;
  font-weight: 400;
  text-decoration: none;
}

@media screen and (width <= 480px) {
  .nav-link {
    font-size: 24px;
  }
}

.nav-link:hover {
  color: var(--color-primary-dark);
  font-weight: 600;
}

.nav-link__subitem {
  color: var(--color-brand-dark);
  font-size: 24px;
  font-weight: 400;
  text-decoration: none;
}

@media screen and (width <= 480px) {
  .nav-link__subitem {
    font-size: 16px;
  }
}

.nav-link__subitem:hover {
  color: var(--color-primary-dark);
  font-weight: 600;
}

.header {
  width: 100%;
  padding: 15px 0;
  position: absolute;
  top: 0;
  left: 0;
}

.header .container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header .container .header-logo {
  z-index: 14;
  width: 120px;
  height: 120px;
  margin-left: 15px;
  position: relative;
}

.header .container .header-rectangle {
  z-index: 13;
  background: linear-gradient(to left, #ada195, #f7ece4);
  align-self: center;
  width: 100%;
  height: 80px;
  position: absolute;
  box-shadow: 0 4px 6px #0000004d;
}

.header .container .burger {
  fill: var(--color-brand-dark);
  z-index: 14;
  background-color: #0000;
  border: 0;
  height: 24px;
  margin-right: 15px;
}

.header .container .burger:focus {
  fill: var(--color-primary-dark);
}

.header .container .burger__icon {
  width: 24px;
  height: 24px;
}

.hero-section {
  background-image: url("backgroundHero.d45f0743.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 115px 0 0;
  position: relative;
}

.hero-section .rectangleCover {
  z-index: 10;
  background: linear-gradient(#f7ece499 0%, #cdc1b780 35%, #ada19566 100%);
  width: 100%;
  max-width: 768px;
  height: 100%;
  position: absolute;
  top: 0;
}

.hero-section .container {
  flex-direction: column;
  display: flex;
}

.hero-section .container__title {
  padding: 25px 32px 5px;
}

.hero-section .container__title .names {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Kyiv Type Titling Heavy3, sans-serif;
  font-size: 64px;
  font-weight: 900;
  line-height: 1;
  display: flex;
}

@media screen and (width <= 480px) {
  .hero-section .container__title .names {
    font-size: 48px;
  }
}

.hero-section .container__title .names .Severyn {
  text-shadow: 0 4px 6px #0000004d;
  z-index: 11;
  align-self: flex-start;
}

.hero-section .container__title .names .pu33le {
  z-index: 11;
}

.hero-section .container__title .names .pu33le .pu33leImg {
  width: 166px;
  height: 131px;
  padding-bottom: 5px;
}

@media screen and (width <= 480px) {
  .hero-section .container__title .names .pu33le .pu33leImg {
    width: 120px;
    height: 96px;
  }

  .hero-section .container__title .names .pu33le {
    transform: translate(50%, 10%);
  }
}

.hero-section .container__title .names .Khrystyna {
  text-shadow: 0 4px 6px #0000004d;
  z-index: 11;
  align-self: flex-end;
}

@media screen and (width >= 768px) {
  .hero-section .container__title {
    margin-left: 15%;
  }
}

.hero-section .container__content {
  flex-direction: column;
  display: flex;
  position: relative;
}

.hero-section .container__content .rectangle1 {
  border: 2px solid;
  border-color: var(--color-brand-dark);
  border-radius: 94px;
  width: 302px;
  height: 402px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.hero-section .container__content .rectangle1 .rectangle2 {
  border: 2px solid;
  border-color: var(--color-brand-light);
  z-index: 11;
  border-radius: 94px;
  width: 302px;
  height: 402px;
  margin-top: 7px;
  margin-left: 7px;
  position: absolute;
  left: 47%;
  transform: translateX(-47%);
}

.hero-section .container__content .rectangle1 .rectangle2 .branchImg {
  z-index: 11;
  width: 220px;
  height: 420px;
  left: 17%;
  transform: translateX(-17%);
}

.hero-section .container__content .rectangle1 .rectangle3 {
  border: 2px solid;
  border-color: var(--color-primary-dark);
  border-radius: 94px;
  width: 302px;
  height: 402px;
  margin-top: 14px;
  margin-left: 14px;
  position: absolute;
  left: 44%;
  transform: translateX(-44%);
}

.hero-section .container__content .content-button {
  z-index: 11;
  align-self: flex-end;
  position: relative;
  top: -70px;
  right: 0;
}

.hero-section__photo {
  width: 267px;
  height: 355px;
  margin-left: 15px;
  position: absolute;
}

.ourWedding-section {
  background-color: #f7ece4;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.ourWedding-section:before {
  content: "";
  opacity: .2;
  background-image: url("backgroundOurWeddingUpSideDown.1e24d27e.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ourWedding-section > * {
  position: relative;
}

.ourWedding-section__container {
  flex-direction: column;
  display: flex;
}

.ourWedding-section__container .title-rectangle {
  text-align: right;
  background: linear-gradient(to left, #f7ece4 0%, #636a60cc 100%);
  align-self: center;
  width: 100%;
  height: 80px;
  box-shadow: 0 4px 6px #0000004d;
}

.ourWedding-section__container .title-rectangle .title-text {
  color: var(--color-brand-dark);
  padding: 10px;
  font-family: Kyiv Type Titling, sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 1;
}

.ourWedding-section__content {
  flex-direction: column;
  align-items: center;
  margin: 30px 5px;
  display: flex;
  position: relative;
}

@media screen and (width <= 480px) {
  .ourWedding-section__content {
    right: 2%;
  }
}

.ourWedding-section__content .rectangle1 {
  border: 2px solid;
  border-color: var(--color-primary-dark);
  border-radius: 94px;
  width: 323px;
  height: 210px;
  position: relative;
}

.ourWedding-section__content .rectangle1 .rectangle2 {
  border: 2px solid;
  border-color: var(--color-brand-light);
  border-radius: 94px;
  width: 323px;
  height: 210px;
  margin-top: 4px;
  margin-left: 4px;
  position: absolute;
}

.ourWedding-section__content .rectangle1 .rectangle2 .branch {
  display: flex;
  position: relative;
}

.ourWedding-section__content .rectangle1 .rectangle2 .branch .branchImg {
  width: 145px;
  height: 215px;
  position: absolute;
  left: 72%;
  transform: translateX(-17%);
}

.ourWedding-section__content .rectangle1 .rectangle3 {
  border: 2px solid;
  border-color: var(--color-brand-dark);
  border-radius: 94px;
  width: 323px;
  height: 210px;
  margin-top: 8px;
  margin-left: 8px;
  position: absolute;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Kyiv Type Titling Medium3;
  font-size: 20px;
  display: flex;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text .month {
  margin-top: 15px;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text .concrete-date {
  display: flex;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text .concrete-date .weekDay {
  align-self: center;
  margin-right: 15px;
  display: inline-block;
  position: relative;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text .concrete-date .weekDay:before {
  content: "";
  border-bottom: 1px solid var(--color-brand-dark);
  opacity: .7;
  pointer-events: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text .concrete-date .weekDay:after {
  content: "";
  border-bottom: 1px solid var(--color-brand-dark);
  opacity: .7;
  pointer-events: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text .concrete-date .date {
  color: var(--color-brand-dark);
  font-size: 48px;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text .concrete-date .time {
  align-self: center;
  margin-left: 15px;
  display: inline-block;
  position: relative;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text .concrete-date .time:before {
  content: "";
  border-bottom: 1px solid var(--color-brand-dark);
  opacity: .7;
  pointer-events: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text .concrete-date .time:after {
  content: "";
  border-bottom: 1px solid var(--color-brand-dark);
  opacity: .7;
  pointer-events: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ourWedding-section__content .rectangle1 .rectangle3 .content-text .place {
  font-size: 16px;
}

.ourWedding-section .dividingLine {
  border: 2px solid var(--color-primary-dark);
  margin: 0;
}

.timing-section {
  background-color: #f7ece4;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.timing-section:before {
  content: "";
  opacity: .2;
  background-image: url("backgroundOurWedding.1e24d27e.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.timing-section > * {
  position: relative;
}

.timing-section__container {
  flex-direction: column;
  display: flex;
}

.timing-section__container .title {
  text-align: center;
  justify-content: space-evenly;
  margin-top: 20px;
  display: flex;
  position: relative;
}

.timing-section__container .title__line {
  width: 377px;
  height: 67px;
}

.timing-section__container .title__text {
  color: var(--color-brand-dark);
  padding: 15px;
  font-family: Kyiv Type Titling Medium3, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
}

.timing-section__container .container-content {
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  margin: 5px 10px;
  display: flex;
  position: relative;
}

@media screen and (width >= 480px) {
  .timing-section__container .container-content {
    justify-content: space-evenly;
  }
}

.timing-section__container .container-content .content-icons {
  flex-direction: column;
  gap: 55px;
  margin-top: 40px;
  display: flex;
}

.timing-section__container .container-content .content-icons .busBack {
  transform: scaleX(-1);
}

.timing-section__container .container-content .content-points {
  flex-direction: column;
  display: flex;
  position: relative;
}

.timing-section__container .container-content .content-points:before {
  content: "";
  background-color: var(--color-primary-dark);
  width: 1.5px;
  height: 95%;
  position: absolute;
  inset: 40px 10px 0;
}

@media screen and (width <= 480px) {
  .timing-section__container .container-content .content-points:before {
    width: 1px;
  }
}

.timing-section__container .container-content .content-points .wrapper-point {
  height: 71px;
  margin-top: 68px;
}

.timing-section__container .container-content .content-points .wrapper-point:nth-child(n+4) {
  height: 72px;
}

@media screen and (width <= 480px) {
  .timing-section__container .container-content .content-points .wrapper-point:nth-child(n+4) {
    height: 42px;
  }

  .timing-section__container .container-content .content-points .wrapper-point {
    height: 41px;
  }
}

.timing-section__container .container-content .content-points .wrapper-point .point {
  border: 1px solid var(--color-primary-dark);
  z-index: 1;
  border-radius: 50%;
  justify-content: center;
  align-self: center;
  width: 21px;
  height: 21px;
  display: flex;
}

.timing-section__container .container-content .content-points .wrapper-point .point .point__inner {
  background-color: var(--color-brand-light);
  z-index: 1;
  border-radius: 50%;
  align-self: center;
  width: 16px;
  height: 16px;
  box-shadow: inset 4px 4px 8px #0000004d;
}

.timing-section__container .container-content .content-allText {
  display: flex;
}

.timing-section__container .container-content .content-allText .content-text {
  color: var(--color-brand-dark);
  flex-direction: column;
  gap: 55px;
  max-width: 272px;
  margin-top: 65px;
  font-family: Kyiv Type Titling Medium3, sans-serif;
  font-size: 14px;
  display: flex;
}

.timing-section__container .container-content .content-allText .content-text .time {
  white-space: nowrap;
  height: 85px;
}

@media screen and (width <= 480px) {
  .timing-section__container .container-content .content-allText .content-text .time {
    height: 55px;
  }
}

.timing-section__container .container-content .content-allText .content-text .content {
  height: 85px;
}

@media screen and (width <= 480px) {
  .timing-section__container .container-content .content-allText .content-text .content {
    height: 55px;
  }
}

.timing-section .dividingLines {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.timing-section .dividingLines .first-dividingLine {
  color: var(--color-primary-dark);
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  font-size: 24px;
  line-height: 0;
  display: flex;
  position: absolute;
}

.timing-section .dividingLines .second-dividingLine {
  border: 2px solid var(--color-primary-dark);
  margin: 15px 0;
}

.timing-section .dividingLines .third-dividingLine {
  color: var(--color-primary-dark);
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 15px 0;
  font-size: 36px;
  line-height: 0;
  display: flex;
  position: absolute;
}

.locations-section {
  background-color: #f7ece4;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.locations-section:before {
  content: "";
  opacity: .2;
  background-image: url("backgroundOurWedding.1e24d27e.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.locations-section > * {
  position: relative;
}

.locations-section__container {
  flex-direction: column;
  margin-top: 10px;
  display: flex;
}

.locations-section__container .title {
  text-align: center;
  justify-content: space-evenly;
  display: flex;
  position: relative;
}

.locations-section__container .title__line {
  width: 377px;
  height: 67px;
}

.locations-section__container .title__text {
  color: var(--color-brand-dark);
  padding: 15px;
  font-family: Kyiv Type Titling Medium3, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
}

.locations-section__container .container-content {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  display: flex;
}

.locations-section__container .container-content .content-text {
  color: var(--color-brand-dark);
  margin: 10px 40px;
  font-family: Kyiv Type Titling Medium3, sans-serif;
  font-size: 16px;
}

.locations-section__container .container-content .church-map, .locations-section__container .container-content .restaurant-map {
  pointer-events: auto;
  border: none;
  border-radius: 10px;
  width: 352px;
  height: 324px;
  position: relative;
}

@media screen and (width <= 480px) {
  .locations-section__container .container-content .church-map, .locations-section__container .container-content .restaurant-map {
    border-radius: 16px;
    width: 252px;
    height: 224px;
  }
}

.locations-section__container .container-content .map {
  border-radius: 16px;
  width: 292px;
  height: 492px;
}

@media screen and (width <= 480px) {
  .locations-section__container .container-content .map {
    width: 202px;
    height: 352px;
  }
}

.locations-section .dividingLines {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.locations-section .dividingLines .first-dividingLine {
  color: var(--color-primary-dark);
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  font-size: 24px;
  line-height: 0;
  display: flex;
  position: absolute;
}

.locations-section .dividingLines .second-dividingLine {
  border: 2px solid var(--color-primary-dark);
  margin: 15px 0;
}

.locations-section .dividingLines .third-dividingLine {
  color: var(--color-primary-dark);
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 15px 0;
  font-size: 36px;
  line-height: 0;
  display: flex;
  position: absolute;
}

.transfer-section {
  background-color: #f7ece4;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.transfer-section:before {
  content: "";
  opacity: .2;
  background-image: url("backgroundOurWeddingUpSideDown.1e24d27e.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.transfer-section > * {
  position: relative;
}

.transfer-section__container {
  flex-direction: column;
  margin-top: 10px;
  display: flex;
}

.transfer-section__container .title {
  text-align: center;
  justify-content: space-evenly;
  display: flex;
  position: relative;
}

.transfer-section__container .title__line {
  width: 377px;
  height: 67px;
}

.transfer-section__container .title__text {
  color: var(--color-brand-dark);
  padding: 15px;
  font-family: Kyiv Type Titling Medium3, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
}

.transfer-section__container .container-content {
  flex-direction: column;
  margin: 20px;
  display: flex;
}

.transfer-section__container .container-content .start-point {
  gap: 20px;
  margin: 20px;
  display: flex;
}

.transfer-section__container .container-content .start-point__icon {
  width: 47px;
  height: 42px;
}

.transfer-section__container .container-content .start-point__text {
  color: var(--color-brand-dark);
  font-family: Kyiv Type Titling Medium3, sans-serif;
  font-size: 24px;
  font-weight: 400;
}

@media screen and (width <= 480px) {
  .transfer-section__container .container-content .start-point__text {
    font-size: 20px;
  }
}

.transfer-section__container .container-content .stop-points {
  margin-bottom: 20px;
  padding-top: 10px;
  display: flex;
  position: relative;
}

.transfer-section__container .container-content .stop-points__icons {
  flex-direction: column;
  display: flex;
}

.transfer-section__container .container-content .stop-points__icons .stop-point__icon {
  width: 48px;
  height: 69px;
  margin: 10px 5px;
}

@media screen and (width <= 480px) {
  .transfer-section__container .container-content .stop-points__icons .stop-point__icon {
    width: 38px;
    height: 59px;
  }
}

.transfer-section__container .container-content .stop-points__text {
  color: var(--color-brand-dark);
  flex-direction: column;
  font-family: Kyiv Type Titling Medium3, sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

@media screen and (width <= 480px) {
  .transfer-section__container .container-content .stop-points__text {
    font-size: 14px;
  }
}

.transfer-section__container .container-content .stop-points__text .stop-point__text {
  height: 79px;
  margin: 5px 15px;
  line-height: 69px;
}

@media screen and (width <= 480px) {
  .transfer-section__container .container-content .stop-points__text .stop-point__text {
    height: 69px;
    line-height: 59px;
  }

  .transfer-section__container .container-content .stop-points__text .stop-point__text.zero {
    padding-top: 20px;
    line-height: 1;
  }
}

.transfer-section .dividingLines {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.transfer-section .dividingLines .first-dividingLine {
  color: var(--color-primary-dark);
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  font-size: 24px;
  line-height: 0;
  display: flex;
  position: absolute;
}

.transfer-section .dividingLines .second-dividingLine {
  border: 2px solid var(--color-primary-dark);
  margin: 15px 0;
}

.transfer-section .dividingLines .third-dividingLine {
  color: var(--color-primary-dark);
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 15px 0;
  font-size: 36px;
  line-height: 0;
  display: flex;
  position: absolute;
}

.questionary-section {
  background-color: #f7ece4;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.questionary-section:before {
  content: "";
  opacity: .2;
  background-image: url("backgroundOurWeddingUpSideDown.1e24d27e.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.questionary-section > * {
  position: relative;
}

.questionary-section__container {
  flex-direction: column;
  margin-top: 10px;
  display: flex;
}

.questionary-section__container .title {
  text-align: center;
  justify-content: space-evenly;
  display: flex;
  position: relative;
}

.questionary-section__container .title__line {
  width: 377px;
  height: 67px;
}

.questionary-section__container .title__text {
  color: var(--color-brand-dark);
  padding: 15px;
  font-family: Kyiv Type Titling Medium3, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
}

.questionary-section__container .container-content {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 20px;
  display: flex;
}

.questionary-section__container .container-content .questionary-section-text {
  color: var(--color-brand-dark);
  width: 456px;
  font-family: Kyiv Type Titling, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (width <= 480px) {
  .questionary-section__container .container-content .questionary-section-text {
    width: 256px;
  }
}

.questionary-section__container .container-content .questionary-section-text-colours {
  color: var(--color-brand-dark);
  font-family: Kyiv Type Titling, sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.questionary-section__container .container-content .colours {
  flex-direction: column;
  align-content: center;
  gap: 20px;
  display: flex;
}

.questionary-section__container .container-content .colours__dark {
  gap: 30px;
  display: flex;
}

.questionary-section__container .container-content .colours__dark .brown {
  border: 1px solid var(--color-primary-dark);
  background-color: #412a1b;
  border-radius: 50%;
  width: 93px;
  height: 93px;
}

.questionary-section__container .container-content .colours__dark .green {
  border: 1px solid var(--color-primary-dark);
  background-color: #334f23;
  border-radius: 50%;
  width: 93px;
  height: 93px;
}

.questionary-section__container .container-content .colours__dark .light-green {
  border: 1px solid var(--color-primary-dark);
  background-color: #93ad82;
  border-radius: 50%;
  width: 93px;
  height: 93px;
}

.questionary-section__container .container-content .colours__light {
  gap: 30px;
  display: flex;
}

.questionary-section__container .container-content .colours__light .beige {
  border: 1px solid var(--color-primary-dark);
  background-color: #cfbda9;
  border-radius: 50%;
  width: 93px;
  height: 93px;
}

.questionary-section__container .container-content .colours__light .pink {
  border: 1px solid var(--color-primary-dark);
  background-color: #f7dada;
  border-radius: 50%;
  width: 93px;
  height: 93px;
}

.questionary-section__container .container-content .colours__light .milk {
  border: 1px solid var(--color-primary-dark);
  background-color: #f8f0e8;
  border-radius: 50%;
  width: 93px;
  height: 93px;
}

.questionary-section .dividingLines {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.questionary-section .dividingLines .first-dividingLine {
  color: var(--color-primary-dark);
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  font-size: 24px;
  line-height: 0;
  display: flex;
  position: absolute;
}

.questionary-section .dividingLines .second-dividingLine {
  border: 2px solid var(--color-primary-dark);
  margin: 15px 0;
}

.questionary-section .dividingLines .third-dividingLine {
  color: var(--color-primary-dark);
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 15px 0;
  font-size: 36px;
  line-height: 0;
  display: flex;
  position: absolute;
}

.donations-section {
  background-color: #f7ece4;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.donations-section:before {
  content: "";
  opacity: .2;
  background-image: url("backgroundOurWeddingUpSideDown.1e24d27e.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.donations-section > * {
  position: relative;
}

.donations-section__container {
  flex-direction: column;
  margin-top: 10px;
  display: flex;
}

.donations-section__container .title {
  text-align: center;
  justify-content: space-evenly;
  display: flex;
  position: relative;
}

.donations-section__container .title__line {
  width: 377px;
  height: 67px;
}

.donations-section__container .title__text {
  color: var(--color-brand-dark);
  padding: 15px;
  font-family: Kyiv Type Titling Medium3, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
}

.donations-section__container .container-content {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 20px;
  display: flex;
}

.donations-section__container .container-content .text-wrapper {
  width: 456px;
}

@media screen and (width <= 480px) {
  .donations-section__container .container-content .text-wrapper {
    width: 256px;
    line-height: .5;
  }
}

.donations-section__container .container-content .donations-section-text {
  color: var(--color-brand-dark);
  width: 456px;
  font-family: Kyiv Type Titling, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

@media screen and (width <= 480px) {
  .donations-section__container .container-content .donations-section-text {
    width: 256px;
  }
}

.donations-section__container .container-content .donations-section-text.highlited {
  color: #000;
  font-weight: 600;
}

.donations-section__container .container-content .donationQR {
  border-radius: 10px;
  width: 181px;
  height: 181px;
}

.photo-section {
  background-color: #f7ece4;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.photo-section:before {
  content: "";
  opacity: .2;
  background-image: url("backgroundOurWeddingUpSideDown.1e24d27e.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.photo-section > * {
  position: relative;
}

.photo-section__container {
  flex-direction: column;
  display: flex;
}

.photo-section__container .title-rectangle {
  text-align: right;
  background: linear-gradient(to left, #f7ece4 0%, #636a60cc 100%);
  align-self: center;
  width: 100%;
  height: 80px;
  box-shadow: 0 4px 6px #0000004d;
}

.photo-section__container .title-rectangle .title-text {
  color: var(--color-brand-dark);
  padding: 10px;
  font-family: Kyiv Type Titling, sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 1;
}

.photo-section__content {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 20px;
  display: flex;
}

.photo-section__content .photo-section-text {
  color: var(--color-brand-dark);
  width: 456px;
  font-family: Kyiv Type Titling, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

@media screen and (width <= 480px) {
  .photo-section__content .photo-section-text {
    width: 256px;
  }
}

.photo-section__content .photo-section-text.additional {
  font-size: 14px;
}

.photo-section__content .photo-folder-wrapper {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 236px;
  display: flex;
}

.photo-section__content .photo-folder-wrapper .photo-section-arrow {
  align-self: flex-start;
}

.photo-section__content .photo-folder-wrapper .photo-section-arrow.rotated {
  align-self: flex-end;
  transform: rotate(180deg);
}

.footer {
  color: var(--color-brand-dark);
  background-image: url("footer-background.cb6c053e.jpg");
  background-color: var(--color-primary-dark);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 24px 0;
  position: relative;
}

@media screen and (width >= 768px) {
  .footer {
    padding: 32px 0;
  }
}

.footer:before, .footer:after {
  content: "";
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
}

.footer .container {
  text-align: left;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.footer__navigation {
  flex-direction: row-reverse;
  gap: 150px;
  margin: 40px;
  display: flex;
}

@media screen and (width <= 480px) {
  .footer__navigation {
    gap: 10px;
    margin: 20px;
  }
}

.footer__navigation__logo {
  flex-grow: 1;
  flex-shrink: 0;
  align-self: center;
  width: 270px;
  height: 270px;
}

@media screen and (width <= 480px) {
  .footer__navigation__logo {
    width: 170px;
    height: 170px;
  }
}

.footer__list {
  white-space: nowrap;
  flex-direction: column;
  display: flex;
}

@media screen and (width <= 480px) {
  .footer__list {
    line-height: .5;
  }
}

.footer__list .nav-link {
  color: var(--color-brand-light);
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

.footer__list .nav-link.main {
  margin: 20px 0;
  font-size: 24px;
  font-weight: 600;
}

.footer__list .nav-link:hover {
  color: var(--color-primary-dark);
}

.footer__links {
  align-self: center;
  margin: 0 50px;
  padding-bottom: 16px;
  display: flex;
}

.footer__links .bride, .footer__links .groom, .footer__links .contact-link, .footer__links .social-media__item {
  color: var(--color-brand-light);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.footer__links .contact-link:hover, .footer__links .social-media__item:hover {
  color: var(--color-primary-dark);
}

.footer__links .social-media__item {
  justify-content: center;
  display: flex;
}

.footer__links .social-media__item .social-media__icon {
  align-self: center;
  width: 50px;
  height: 50px;
}
/*# sourceMappingURL=index.dcc9f461.css.map */
